import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Message} from '../models/Message';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  baseUrl = '';
  constructor(private http: HttpClient) { }
  getMessage(message, userId, isMedici, context?): Observable<Message> {
    if (isMedici) {
      this.baseUrl = 'https://dev.zana.com/medici1.0/brain/?';
    } else {
      this.baseUrl = window.location.protocol + '//' + window.location.host + '/brain';
      // this.baseUrl = 'http://localhost:8080/brain';
    }
    console.log('URL: ' + this.baseUrl);
    console.log('context: ' + context);
    // let params = new HttpParams();
    // params = params.append('utterance', `${message}`);
    // params = params.append('sid', userId);
    // params = params.append('context', context);
    // params = params.append('lang', 'en');
    // return this.http.get<Message>(this.baseUrl, {params});
    const headers = { 'Content-Type': 'application/json' };
    const jsonRequest = {utterance: message, sid: userId, context, lang: 'en'};
    console.log('JSON: ' + jsonRequest);
    return this.http.post<Message>(this.baseUrl, jsonRequest, {headers});
  }
}
